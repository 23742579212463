import React from 'react';
import { Grid, Tab } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import { find } from '../../../utils/lodash';
import { APP_NAME, ADMIN_SYSTEM_SETUP } from '../../../utils/constant';
import { CustomSegment } from '../../../components';
import '../admin.scss';
// import { exportSystemUsers } from '../../../store/actions';
import { ParentHelpComponent, IndustrySectorTypes, VenueTypes } from './forms';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Export from './forms/export';
// import { exportSystemUsers } from 'your-actions-file';

const SystemSetting = ({ match }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { auth, user, helpTips } = useSelector(state => ({
  //   auth: state.authentication,
  //   user: state.authentication.user,
  //   helpTips: state.extra.activityHelpTips,
  //   ...state.user,
  // }));

  useEffect(() => {
    try {
      if (match.params.type) {
        changeRoute(match.params.type);
      }
    } catch (error) {
      console.error('error :', error);
    }
  }, [match.params.type]);

  useEffect(() => {
    document.title = `System Settings | ${APP_NAME}`;
  }, []);

  const generateTabs = () => {
    const panes = [
      {
        menuItem: 'Industry sector types',
        render: () => (
          <Tab.Pane attached={false} className="custom-tab less-padding">
            <CustomSegment
              title="Industry Sector Types"
              children={<IndustrySectorTypes />}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Venue Types',
        render: () => (
          <Tab.Pane attached={false} className="custom-tab">
            <CustomSegment title="Venue Types" children={<VenueTypes />} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'System Help',
        render: () => (
          <Tab.Pane attached={false} className="custom-tab">
            <CustomSegment
              title="System Help"
              children={<ParentHelpComponent />}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'System Data Export',
        render: () => (
          <Tab.Pane attached={false} className="custom-tab">
            <CustomSegment title="System Data Export" children={<Export />} />
          </Tab.Pane>
        ),
      },
    ];

    return panes;
  };

  const onTabClick = (data) => {
    changeRoute(data.activeIndex);
  };

  // const onClickUsersExport = async () => {
  //   await exportSystemUsersDispatch();
  // const { usersCsvLink } = this.props;
  //   if (!isEmpty(usersCsvLink)) {
  //     window.open(usersCsvLink.download_link, '_blank');
  //   }
  // };

  const changeRoute = async (value) => {
    try {
      const tab = await find(ADMIN_SYSTEM_SETUP, (o) => {
        return o.id === value || o.route === value;
      });
      setCurrentTab(tab.id);
      navigate(`/system-settings/${tab.route}`);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <Grid.Row>
      <Tab
        menu={{ borderless: true, attached: false, tabular: false }}
        panes={generateTabs()}
        className={isMobile ? 'custom-tabs-mobile' : 'custom-tabs'}
        activeIndex={currentTab}
        onTabChange={(e, data) => onTabClick(data)}
      />
    </Grid.Row>
  );
};

export default SystemSetting;

import React, { useEffect } from 'react';
import { EXPORT_DATA_TYPES } from '../../../../utils/constant';
import {
  systemDataExport,
  getAllOrganisations,
} from '../../../../store/modules/admin';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Button, Dropdown, Segment } from 'semantic-ui-react';
import { useState } from 'react';
import { isEmpty } from 'lodash';
const Export = () => {
  const dispatch = useDispatch();

  const [organisations, setOrganisations] = useState([]);
  const [organisationId, setOrganisationId] = useState(null);

  const loading = useSelector((state) => state.admin.dataExportLoading);
  const organisationsAndIndividuals = useSelector(
    (state) => state.admin.organisationsAll
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllOrganisations());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(organisationsAndIndividuals)) {
      const organisations = organisationsAndIndividuals.filter(
        (organisation) =>
          organisation.type === 'ORGANISATION' && !organisation.isDemo
      );
      setOrganisations(organisations);
    }
  }, [organisationsAndIndividuals]);

  const onChangeOrganisation = async (e, data) => {
    if (data.value === 0) {
      setOrganisationId(null);
    } else {
      setOrganisationId(data.value);
    }
  };

  const organisationOptions = [
    { key: -1, value: 0, text: 'All organisations' },
    ...organisations.map((item, key) => ({
      key,
      value: item.id,
      text: item.name,
    })),
  ];

  const onButtonClick = (exportType) => {
    //organisation id didn't pass for the download all export button click
    dispatch(systemDataExport(exportType, organisationId));
  };
  return (
    <Segment className="accordion-container" loading={loading}>
      <div className="all-export-download-padded organisation-dropdown">
        <Dropdown
          placeholder="Filter by Organisation"
          fluid
          search
          selection
          options={organisationOptions}
          onChange={(e, data) => onChangeOrganisation(e, data)}
        />
      </div>

      <div className="export-list">
        {EXPORT_DATA_TYPES.map((item, index) => (
          <div className="data-export-container" key={index}>
            <label className="form-text">{item.label}</label>
            <Button
              key={index}
              onClick={() => onButtonClick(item.param)}
              className="Primary-Button"
            >
              Export
            </Button>
          </div>
        ))}
      </div>
    </Segment>
  );
};

export default Export;
